import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')];

export const server_loads = [];

export const dictionary = {
	"/": [4],
	"/(login-layout)/accept-invite": [15,[3]],
	"/(login-layout)/change-email": [16,[3]],
	"/(login-layout)/confirm-email": [17,[3]],
	"/(login-layout)/forgot-password": [18,[3]],
	"/(login-layout)/login": [19,[3]],
	"/(admin-layout)/order/list": [6,[2]],
	"/(admin-layout)/order/new": [7,[2]],
	"/(admin-layout)/order/[id]": [5,[2]],
	"/(admin-layout)/processes": [8,[2]],
	"/(admin-layout)/profile": [9,[2]],
	"/(admin-layout)/reports": [10,[2]],
	"/(login-layout)/reset-password": [20,[3]],
	"/(admin-layout)/settings": [11,[2]],
	"/(admin-layout)/stripe": [12,[2]],
	"/(admin-layout)/user/list": [14,[2]],
	"/(admin-layout)/user/[id]": [13,[2]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};